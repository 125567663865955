<template id="optasetup">
  <b-container fluid>
    <h3>Opta Player Setup</h3>
    <b-row>
      <b-col sm="6" md="6" lg="4">
        <h5>Skills</h5>
        <b-row no-gutters>
          <b-col cols="1"> # </b-col>
          <b-col cols="2"> Name </b-col>
          <!--b-col cols="2">
            Abbr
        </b-col-->
          <b-col> Label </b-col>
          <b-col cols="1" title="standard"> Std </b-col>
          <b-col cols="1" title="reverse"> Rev </b-col>
          <b-col cols="1" title="average"> Avg </b-col>
          <!--b-col cols="1">
            %
        </b-col-->
          <!--b-col cols="1">
            Act
        </b-col-->
        </b-row>
        <div style="max-height: 73vh; overflow-y: scroll">
          <draggable
            v-model="skills"
            :group="{ name: 'skills', pull: 'clone', put: false }"
          >
            <div v-for="skill in skills" :key="skill.id">
              <b-card no-body class="mb-1" style="cursor: move">
                <b-row no-gutters align-v="center">
                  <b-col cols="1">
                    {{ skill.order }}
                  </b-col>
                  <b-col cols="2">
                    {{ skill.name }}
                  </b-col>
                  <!--b-col cols="2">
                    <b-form-input v-model="skill.sigla"></b-form-input>
                </b-col-->
                  <b-col>
                    <b-form-input
                      v-model="skill.label"
                      placeholder="Enter label for skill"
                      :title="skill.label"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="1">
                    <b-form-checkbox
                      v-model="skill.standard"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="1">
                    <b-form-checkbox
                      v-model="skill.reverse"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="1">
                    <b-form-checkbox
                      v-model="skill.average"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </b-col>
                  <!--b-col cols="1">
                    <b-form-checkbox
                      disabled
                      v-model="skill.percent"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                </b-col-->
                  <!--b-col cols="1">
                    <b-form-checkbox
                      v-model="skill.active"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                </b-col-->
                </b-row>
              </b-card>
            </div>
          </draggable>
        </div>
        <b-button size="sm" variant="primary" class="mt-2" @click="updateSkills"
          >Update skills</b-button
        >
      </b-col>
      <b-col>
        <h5>Roles</h5>
        <div style="max-height: 75vh; overflow-y: scroll">
          <b-row no-gutters id="roles_content" ref="roles_content">
            <b-col
              sm="12"
              md="12"
              lg="6"
              v-for="ruolo in ruoli"
              :key="ruolo.id"
              class="p-1"
            >
              <b-card :header="ruolo.ruolo" no-body>
                <b-card-body class="py-1">
                  <b-row>
                    <b-col class="border">
                      <span>transition +</span>
                      <draggable
                        :list="ruolo.opta_skills_fase1"
                        :group="{ name: 'skills', pull: 'clone' }"
                        @change="addSkillRuoloFase1($event, ruolo)"
                        style="min-height: 50px; margin-bottom: 10px"
                      >
                        <div
                          v-for="item in ruolo.opta_skills_fase1"
                          :key="ruolo.id + '_fase1_' + item.id"
                        >
                          <b-badge
                            variant="primary"
                            :title="item.label"
                            style="width: 190px"
                            class="py-1"
                          >
                            <b-row no-gutters>
                              <b-col>
                                <div
                                  style="
                                    cursor: move;
                                    font-size: 0.8rem;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 165px;
                                    text-align: left;
                                  "
                                >
                                  {{ item.name }} -
                                  {{ item.label }}
                                </div>
                              </b-col>
                              <b-col cols="1">
                                <b-icon
                                  icon="x"
                                  scale="2"
                                  style="cursor: pointer"
                                  @click="removeSkillFase1(ruolo, item.id)"
                                ></b-icon>
                              </b-col>
                            </b-row>
                          </b-badge>
                        </div>
                      </draggable>
                    </b-col>
                    <b-col class="border">
                      <span>transition -</span>
                      <draggable
                        :list="ruolo.opta_skills_fase2"
                        :group="{ name: 'skills', pull: 'clone' }"
                        @change="addSkillRuoloFase2($event, ruolo)"
                        style="min-height: 50px; margin-bottom: 10px"
                      >
                        <div
                          v-for="item in ruolo.opta_skills_fase2"
                          :key="ruolo.id + '_fase2_' + item.id"
                        >
                          <b-badge
                            variant="primary"
                            :title="item.label"
                            style="width: 190px"
                            class="py-1"
                          >
                            <b-row no-gutters>
                              <b-col>
                                <div
                                  style="
                                    cursor: move;
                                    font-size: 0.8rem;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 165px;
                                    text-align: left;
                                  "
                                >
                                  {{ item.name }} -
                                  {{ item.label }}
                                </div>
                              </b-col>
                              <b-col cols="1">
                                <b-icon
                                  icon="x"
                                  scale="2"
                                  style="cursor: pointer"
                                  @click="removeSkillFase2(ruolo, item.id)"
                                ></b-icon>
                              </b-col>
                            </b-row>
                          </b-badge>
                        </div>
                      </draggable>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="border">
                      <span>offensive</span>
                      <draggable
                        :list="ruolo.opta_skills_fase3"
                        :group="{ name: 'skills', pull: 'clone' }"
                        @change="addSkillRuoloFase3($event, ruolo)"
                        style="min-height: 50px; margin-bottom: 10px"
                      >
                        <div
                          v-for="item in ruolo.opta_skills_fase3"
                          :key="ruolo.id + '_fase3_' + item.id"
                        >
                          <b-badge
                            variant="primary"
                            :title="item.label"
                            style="width: 190px"
                            class="py-1"
                          >
                            <b-row no-gutters>
                              <b-col>
                                <div
                                  style="
                                    cursor: move;
                                    font-size: 0.8rem;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 165px;
                                    text-align: left;
                                  "
                                >
                                  {{ item.name }} -
                                  {{ item.label }}
                                </div>
                              </b-col>
                              <b-col cols="1">
                                <b-icon
                                  icon="x"
                                  scale="2"
                                  style="cursor: pointer"
                                  @click="removeSkillFase3(ruolo, item.id)"
                                ></b-icon>
                              </b-col>
                            </b-row>
                          </b-badge>
                        </div>
                      </draggable>
                    </b-col>
                    <b-col class="border">
                      <span>defensive</span>
                      <draggable
                        :list="ruolo.opta_skills_fase4"
                        :group="{ name: 'skills', pull: 'clone' }"
                        @change="addSkillRuoloFase4($event, ruolo)"
                        style="min-height: 50px; margin-bottom: 10px"
                      >
                        <div
                          v-for="item in ruolo.opta_skills_fase4"
                          :key="ruolo.id + '_fase4_' + item.id"
                        >
                          <b-badge
                            variant="primary"
                            :title="item.label"
                            style="width: 190px"
                            class="py-1"
                          >
                            <b-row no-gutters>
                              <b-col>
                                <div
                                  style="
                                    cursor: move;
                                    font-size: 0.8rem;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 165px;
                                    text-align: left;
                                  "
                                >
                                  {{ item.name }} -
                                  {{ item.label }}
                                </div>
                              </b-col>
                              <b-col cols="1">
                                <b-icon
                                  icon="x"
                                  scale="2"
                                  style="cursor: pointer"
                                  @click="removeSkillFase4(ruolo, item.id)"
                                ></b-icon>
                              </b-col>
                            </b-row>
                          </b-badge>
                        </div>
                      </draggable>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <b-button size="sm" variant="primary" class="mt-2" @click="updateRoles"
          >Update roles</b-button
        >
        <b-button
          size="sm"
          variant="secondary"
          class="mt-2 ml-2"
          @click="pdfRoles"
          >Print roles</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import draggable from "vuedraggable";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
  data: function () {
    return {
      ruoli: [],
      skills: [],
    };
  },

  components: {
    draggable,
  },

  created: function () {
    this.getSkills();
    this.getRuoli();
  },

  methods: {
    getSkills() {
      this.$http.get("/opta/skills").then((response) => {
        this.skills = response.data;
      });
    },
    getRuoli() {
      this.$http.get("/opta/ruoli/skills").then((response) => {
        this.ruoli = response.data;
      });
    },
    addSkillRuoloFase1: function (evt, ruolo) {
      if (evt.added) {
        this.removeSkillFase2(ruolo, evt.added.element.id);
        this.removeSkillFase3(ruolo, evt.added.element.id);
        this.removeSkillFase4(ruolo, evt.added.element.id);
      }
    },
    addSkillRuoloFase2: function (evt, ruolo) {
      if (evt.added) {
        this.removeSkillFase1(ruolo, evt.added.element.id);
        this.removeSkillFase3(ruolo, evt.added.element.id);
        this.removeSkillFase4(ruolo, evt.added.element.id);
      }
    },
    addSkillRuoloFase3: function (evt, ruolo) {
      if (evt.added) {
        this.removeSkillFase1(ruolo, evt.added.element.id);
        this.removeSkillFase2(ruolo, evt.added.element.id);
        this.removeSkillFase4(ruolo, evt.added.element.id);
      }
    },
    addSkillRuoloFase4: function (evt, ruolo) {
      if (evt.added) {
        this.removeSkillFase1(ruolo, evt.added.element.id);
        this.removeSkillFase2(ruolo, evt.added.element.id);
        this.removeSkillFase3(ruolo, evt.added.element.id);
      }
    },
    removeSkillFase1: function (ruolo, skill_id) {
      for (var index in ruolo.opta_skills_fase1) {
        var skill = ruolo.opta_skills_fase1[index];
        if (skill.id == skill_id) {
          ruolo.opta_skills_fase1.splice(index, 1);
          break;
        }
      }
    },
    removeSkillFase2: function (ruolo, skill_id) {
      for (var index in ruolo.opta_skills_fase2) {
        var skill = ruolo.opta_skills_fase2[index];
        if (skill.id == skill_id) {
          ruolo.opta_skills_fase2.splice(index, 1);
          break;
        }
      }
    },
    removeSkillFase3: function (ruolo, skill_id) {
      for (var index in ruolo.opta_skills_fase3) {
        var skill = ruolo.opta_skills_fase3[index];
        if (skill.id == skill_id) {
          ruolo.opta_skills_fase3.splice(index, 1);
          break;
        }
      }
    },
    removeSkillFase4: function (ruolo, skill_id) {
      for (var index in ruolo.opta_skills_fase4) {
        var skill = ruolo.opta_skills_fase4[index];
        if (skill.id == skill_id) {
          ruolo.opta_skills_fase4.splice(index, 1);
          break;
        }
      }
    },
    updateSkills() {
      this.$http.post("/opta/update/skills", this.skills).then((response) => {
        this.getSkills();
        this.$store.commit("msgPush", {
          msg: response.data.message,
          status: 1,
        });
      });
    },
    updateRoles() {
      this.$http
        .post("/opta/update/ruoli/skills", this.ruoli)
        .then((response) => {
          this.getRuoli();
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
        });
    },
    pdfRoles() {
      var canvasElement = document.createElement("canvas");
      html2canvas(this.$refs.roles_content, { canvas: canvasElement }).then(
        function (canvas) {
          var imgData = canvas.toDataURL("image/png");
          var imgWidth = 210;
          var pageHeight = 295;
          var imgHeight = (canvas.height * imgWidth) / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF("p", "mm");
          var position = 0;

          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position += heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save("roles_skills.pdf");
        }
      );
    },
  },

  filters: {
    formatDateHour: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
    formatDate: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("MM/DD/YYYY");
      }
      return "";
    },
  },
};
</script>
